// /*eslint-disable*/

import React, { Fragment } from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import BrandIcon from "@material-ui/icons/LocalOffer";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import FilterIcon from "@material-ui/icons/FilterList";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import StarsIcon from "@material-ui/icons/Star";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};



export default function StarRatingAnalytic() {

  const axios = require('axios');




  // SMAT Log
  React.useEffect(() => {
    const axios = require('axios');

    let restGETurl = `${"smatCategory="}${"Custom Categories"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    // Get Master Model Table 
    axios.get(SMATrestAPI.generalFilter[0].allModels).then(function (response) { 

    // handle success
    //localStorage.setItem("modelList", JSON.stringify(response.data.getFilter));
    setFullModelList(response.data.getFilter);

    let retrievedModelList = response.data.getFilter
    let uniqueBrand = [...new Set(retrievedModelList.map(x => x.Brand))];
    uniqueBrand.unshift("Sony");

    setBrandItems(uniqueBrand);


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });


    return () => {
      // Do some cleanup  
      setBrandItems([""]);
     }

  }, [])

 

  // Loading Param
  const [progress, setProgress] = React.useState(10);

  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");


  // Loading by Section
  const [summaryTableDataIsLoading, setSummaryTableDataIsLoading] = React.useState(false);
  const [categoryDetailDataIsLoading, setCategoryDetailDataIsLoading] = React.useState(false);



  // Get Trend Data via SMAT RESTAPI
  // filters
  const [fullModelList, setFullModelList] = React.useState(['']);

  const [brandItems, setBrandItems] = React.useState(['']);
  const [brandSelection, setBrandSelection] = React.useState('');

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState('');

  const [modelItems, setModelItems] = React.useState([]);
  const [modelSelection, setModelSelection] = React.useState([]);

  const [websiteItems, setWebsiteItems] = React.useState([]);
  const [websiteSelection, setWebsiteSelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(false);

  const [analyticItems, setAnalyticItems] = React.useState(["Gaming Monitor", "Headphone", "Home Wireless Speaker", "Home Theater System", "Wireless Speaker", "HAS", "Hearing Aid", "TV"]);
  const [analyticSelection, setAnalyticSelection] = React.useState('');

  const [customCategoryItem, setCustomCategoryItem] = React.useState([]);
  const [customCategorySelection, setCustomCategorySelection] = React.useState('');

  // const [customStarItem, setCustomStarItem] = React.useState(["All", "5-Star", "4-Star", "3-Star", "2-Star", "1-Star"]);
  // const [customStarSelection, setCustomStarSelection] = React.useState("All");


  const [customStarSelection, setCustomStarSelection] = React.useState({
    fiveStar: true,
    fourStar: true,
    threeStar: true,
    twoStar: true,
    oneStar: true
  });


  //Page Sections
  // Summary Table
  const [summaryTableData, setSummaryTableData] = React.useState([]);
  const [summaryStarTableData, setSummaryStarTableData] = React.useState([]);

  // Full Review 
  const [fullReviewData, setFullReviewData] = React.useState('');
  const [fullReviewModal, setFullReviewModal] = React.useState(false);
  const [fullReviewDrillDownIsLoading, setFullReviewDrillDownIsLoading] = React.useState(false);

  // Category Detail Value Box 
  const [categoryDetailValueBoxPositive, setCategoryDetailValueBoxPositive] = React.useState('');
  const [categoryDetailValueBoxNeutral, setCategoryDetailValueBoxNeutral] = React.useState('');
  const [categoryDetailValueBoxNegative, setCategoryDetailValueBoxNegative] = React.useState('');
  const [categoryDetailValueBoxRating, setCategoryDetailValueBoxRating] = React.useState('');
  const [uniqueTrendStarColor, setUniqueTrendStarColor] = React.useState('info');

  // Category Detail Trend Data
  const [categoryDetailTrendData, setCategoryDetailTrendData] = React.useState([]);
  const [categoryDetailTopicBreakDownData, setCategoryDetailTopicBreakDownData] = React.useState([]);
  const [categoryDetailTopicDescriptionPositiveData, setCategoryDetailTopicDescriptionPositiveData] = React.useState([]);
  const [categoryDetailTopicDescriptionNegativeData, setCategoryDetailTopicDescriptionNegativeData] = React.useState([]);
  const [categoryDetailIndividualSentenceData, setCategoryDetailIndividualSentenceData] = React.useState([]);


  // Raw Data
  const [rawData, setRawData] = React.useState([]);

  const handleBrand= event => {
    setBrandSelection(event.target.value);
    setCategorySelection('');
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCategory = event => {
    setCategorySelection(event.target.value);
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleModel = event => {
    setModelSelection(event.target.value);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };


  const handleWebsite = event => {
    setWebsiteSelection(event.target.value);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCountry = event => {
    setCountrySelection(event.target.value);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  // const handleDateStart = event => {
  //   let startDate = event._d;
  //   startDate = new Date(event._d);
  //   let year = startDate.getFullYear();
  //   let month = startDate.getMonth()+1;
  //   let dt = startDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   startDate = (year+'-' + month + '-'+dt);


  //   if(isNaN(startDate) == false){
  //     setDateStartSelection(startDate);
  //   }


  // };

  // const handleDateEnd = event => {
  //   let endDate = event._d;
  //   endDate = new Date(event._d);
  //   let year = endDate.getFullYear();
  //   let month = endDate.getMonth()+1;
  //   let dt = endDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   endDate = (year+'-' + month + '-'+dt);

  //   setDateEndSelection(endDate);

  //   if(isNaN(endDate) == false){
  //     setDateEndSelection(endDate);
  //   }

  // };


  const handleDateStart = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
  
      setDateStartSelection(aDate);
    }


  }

  const handleDateEnd = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelection(aDate);
    }


  }


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  }

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  }


  const handleCustomCategorySelection = event => {
    setCustomCategorySelection(event.target.value);

  }

  const handleCustomStarSelection = (data) => {

    switch(data) {

      case "fiveStar":
        setCustomStarSelection((prevState) => {
          return({
            ...prevState,
            fiveStar: !customStarSelection.fiveStar
          });
        });
        break;
  
      case "fourStar":
        setCustomStarSelection((prevState) => {
          return({
            ...prevState,
            fourStar: !customStarSelection.fourStar
          });
        });
        break;
  
        case "threeStar":
          setCustomStarSelection((prevState) => {
          return({
            ...prevState,
            threeStar: !customStarSelection.threeStar
          });
        });
        break;
  
        case "twoStar":
          setCustomStarSelection((prevState) => {
          return({
            ...prevState,
            twoStar: !customStarSelection.twoStar
          });
        });
        break;
  
        case "oneStar":
          setCustomStarSelection((prevState) => {
          return({
            ...prevState,
            oneStar: !customStarSelection.oneStar
          });
        });
        break;
  
        case "updateChart":
  
          onClickCategoryDetailChartsStarFilter();
  
        break;
      default:
  
  
        
    }
  

    

  }

    // Get Filter Data from SMAT REST API - Brand
    // React.useEffect(() => {
    //   const axios = require('axios');
  
    //   axios.get(SMATrestAPI.generalFilter[0].getBrand).then(function (response) { 
    //     // handle success
    //     setBrandItems(response.data.getBrand);
  
    //   }).catch(function (error) { 
    //     // handle error
    //     console.log(error);
    //   }).then(function () { 
    //     // always executed
    //   });
  
  
  
    // }, [])
  
  // Get Filter Data from SMAT REST API - Category
  React.useEffect(() => {
    
    const controller = new AbortController();
  
    if(brandSelection !== ''){
  
      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = [...new Set(result.map(x => x['Product.Category']))];

      setCategoryItems(result);
  
    }

    return () => controller.abort();

  }, [brandSelection])
  
  
  
  // Get Filter Data from SMAT REST API - Model
  React.useEffect(() => {
  
  const controller = new AbortController();
  
  if(categorySelection !== ''){
  
    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = [...new Set(result.map(x => x['Model']))];

    setModelItems(result);


  }

    return () => controller.abort();

  }, [categorySelection])
  
  
  
  // Get Filter Data from SMAT REST API - Website
  React.useEffect(() => {
  
  const controller = new AbortController();
  
  if(modelSelection !== ''){
  
    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9]);
    result = [...new Set(result.map(x => x['Website']))];

    setWebsiteItems(result);
  
  }

  return () => controller.abort();

  }, [modelSelection])
  
  
  
  // Get Filter Data from SMAT REST API - Country
  React.useEffect(() => {
  
  const controller = new AbortController();
  
  if(categorySelection !== ''){
  
    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9]);
    result = result.filter(x => x['Website'] == websiteSelection[0] || x['Website'] == websiteSelection[1] || x['Website'] == websiteSelection[2] || x['Website'] == websiteSelection[3] || x['Website'] == websiteSelection[4] || x['Website'] == websiteSelection[5] || x['Website'] == websiteSelection[6] || x['Website'] == websiteSelection[7] || x['Website'] == websiteSelection[8] || x['Website'] == websiteSelection[9]);
    result = [...new Set(result.map(x => x['Country']))];

    setCountryItems(result);


  
  }

  return () => controller.abort();

  }, [websiteSelection])
  
  
  // Get Filter Data from SMAT REST API - Date
  React.useEffect(() => {
  
    const controller = new AbortController();
  
  if(countrySelection !== ''){
  
    var restGETurl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.generalFilter[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success
  
      setDateStartItem(response.data.startDate);
      setDateEndItem(response.data.endDate);
  
      setDateStartSelection(response.data.startDate);
      setDateEndSelection(response.data.endDate);
  
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    return () => controller.abort();
  
  }
  }, [countrySelection])
  
  





  const onClickChartData = event => {

    const axios = require('axios');


      // Send Model Information to SMAT DB after user clicks submit
      var restGETurl0 = `${"page="}${"Custom Categories"}${"&brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
      restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
      restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`).then(function (response) { 

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });
  

    setSummaryTableDataIsLoading(true);
    let restGETurl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&analyticType="}${analyticSelection}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.customCategories[0].summaryTable}${restGETurl}`).then(function (response) { 
      // handle success
  
      setSummaryTableData(response.data.summaryTable);
      setSummaryStarTableData(response.data.summaryTableStar);

      let categoryData = response.data.summaryTable.map(data => data['Category']);
      setCustomCategoryItem(categoryData.sort());

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setProgress(100);
      setSummaryTableDataIsLoading(false);

    });

    getRawData();

  }



  const getRawData = async () => {
    // Raw Data
    let restGETurl15a = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl15a = restGETurl15a.replaceAll(",", '%2C'); // replace comma for url
    restGETurl15a = restGETurl15a.replaceAll(" ", '%20'); // empty space for url
  
    try {
      const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].rawData}${restGETurl15a}`);
      setRawData(resp.data.rawData);
  
    } catch (err) {
        // Handle Error Here
        console.error(err);
  
  
    }
  
  
  };


  const onClickCategoryDetailCharts = event => {

    // Value Box 
    let categoryDetailValueBoxData = summaryTableData.filter(data => data['Category'] === customCategorySelection);

    let posData = (categoryDetailValueBoxData[0]["Positive Count"] / (categoryDetailValueBoxData[0]["Positive Count"] + categoryDetailValueBoxData[0]["Neutral Count"]  + categoryDetailValueBoxData[0]["Negative Count"])) * 100
    posData = posData.toFixed(1);
    posData = `${posData}${"%"}`;
    
    let negData = (categoryDetailValueBoxData[0]["Negative Count"] / (categoryDetailValueBoxData[0]["Positive Count"] + categoryDetailValueBoxData[0]["Neutral Count"]  + categoryDetailValueBoxData[0]["Negative Count"])) * 100
    negData = negData.toFixed(1);
    negData = `${negData}${"%"}` 

    let neuData = (categoryDetailValueBoxData[0]["Neutral Count"] / (categoryDetailValueBoxData[0]["Positive Count"] + categoryDetailValueBoxData[0]["Neutral Count"]  + categoryDetailValueBoxData[0]["Negative Count"])) * 100
    neuData = neuData.toFixed(1);
    neuData = `${neuData}${"%"}` 

    setCategoryDetailValueBoxPositive(posData);
    setCategoryDetailValueBoxNeutral(neuData);
    setCategoryDetailValueBoxNegative(negData);

    setCategoryDetailValueBoxRating(categoryDetailValueBoxData[0]['Average Star']);

    setValueBoxColor(categoryDetailValueBoxData[0]['Average Star']);


    
    const axios = require('axios');

    // Category Detail Trend Data

    let starSelection = "All";

    setCategoryDetailDataIsLoading(true);
    let restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&analyticType="}${analyticSelection}${"&categorySelection="}${customCategorySelection}${"&starFilter="}${starSelection}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.customCategories[0].summaryDetail}${restGETurl1}`).then(function (response) { 
      // handle success

      setCategoryDetailTrendData(response.data.topicTrend);
      setCategoryDetailTopicBreakDownData(response.data.topicBreakdown);
      setCategoryDetailTopicDescriptionPositiveData(response.data.topicSentimentPos);
      setCategoryDetailTopicDescriptionNegativeData(response.data.topicSentimentNeg);
      setCategoryDetailIndividualSentenceData(response.data.individualSentence);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setCategoryDetailDataIsLoading(false);
      //setProgress(30);
    });


    setCustomStarSelection((prevState) => {
      return({
        ...prevState,
          fiveStar: true,
          fourStar: true,
          threeStar: true,
          twoStar: true,
          oneStar: true
      });
    });


  }




  const onClickCategoryDetailChartsStarFilter = event => {
    
    const axios = require('axios');

    // Category Detail Trend Data

    let starSelection = "All";

    let filterResult = customStarSelection.fiveStar.toString() + "," + customStarSelection.fourStar.toString() + "," + customStarSelection.threeStar.toString() + "," + customStarSelection.twoStar.toString() + "," + customStarSelection.oneStar.toString();


    setCategoryDetailDataIsLoading(true);
    let restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&analyticType="}${analyticSelection}${"&categorySelection="}${customCategorySelection}${"&starFilter="}${filterResult}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.customCategories[0].summaryDetail}${restGETurl1}`).then(function (response) { 
      // handle success

      setCategoryDetailTrendData(response.data.topicTrend);
      setCategoryDetailTopicBreakDownData(response.data.topicBreakdown);
      setCategoryDetailTopicDescriptionPositiveData(response.data.topicSentimentPos);
      setCategoryDetailTopicDescriptionNegativeData(response.data.topicSentimentNeg);
      setCategoryDetailIndividualSentenceData(response.data.individualSentence);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setCategoryDetailDataIsLoading(false);
      //setProgress(30);
    });




  }



  
  const setValueBoxColor = (data) => {
    let ratingMod;

    if (typeof data !== 'undefined'){
      ratingMod = data.toString().substr(0, 1);
      ratingMod = ratingMod.trim();

      switch(ratingMod) {
        case "5":
          setUniqueTrendStarColor("success");
          break;
        case "4":
          setUniqueTrendStarColor("success");
          break;
        case "3":
          setUniqueTrendStarColor("warning");
          break;
        case "2":
          setUniqueTrendStarColor("danger");
        case "1":
          setUniqueTrendStarColor("danger");
          break;
        default:
          setUniqueTrendStarColor("info");
      }
    }
  }


  const onClickFullReview = (model, country, website, reviewID) => {
    setFullReviewData(''); // reset the data
    setFullReviewDrillDownIsLoading(true);  // Load Spinner
    const axios = require('axios');
  
    // Full Review
    //setCategoryDetailSoundtopicInsightIsLoading(true);
    let restGETurlFullReview = `${"&model="}${model}${"&website="}${website}${"&country="}${country}${"&reviewerID="}${reviewID}`;
    restGETurlFullReview = restGETurlFullReview.replaceAll(",", '%2C'); // replace comma for url
    restGETurlFullReview = restGETurlFullReview.replaceAll(" ", '%20'); // empty space for url
  
    axios.get(`${SMATrestAPI.starRatingAnalytic[0].fullReview}${restGETurlFullReview}`).then(function (response) { 
      // handle success
 
  
      setFullReviewData(response.data.fullReview);
    
      
    
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setFullReviewModal(true); // Show Pop-up
      setTimeout(() => {setFullReviewDrillDownIsLoading(false)}, 500); // Turn Spinner Off after .5 second
    });
  
  }



// Category Summary - Table using material-table


const summaryTableDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Analytic Type',
    field: 'analytic'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'List of OR Words',
    field: 'listOrWords',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'List of AND Words',
    field: 'listAndWords'
  },
  {
    title: 'Positive Count',
    field: 'positiveCount'
  },
  {
    title: 'Negative Count',
    field: 'negativeCount'
  },
  {
    title: 'Neutral Count',
    field: 'neutralCount'
  },
  {
    title: 'Star Rating',
    field: 'starRating'
  }
];



const summaryTableDataTableDataSet = summaryTableData.map((data) => {
  return ({
    id: data.id,
    model: data.Model,
    analytic: data['Analytic Type'],
    category: data['Category'],
    listOrWords: data['List of OR words'],
    listAndWords: data['List of AND words'],
    positiveCount: data['Positive Count'],
    negativeCount: data['Negative Count'],
    neutralCount: data['Neutral Count'],
    starRating: data['Average Star']
  })
});




// Category Summary Star - Table using material-table


const summaryStarTableDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Analytic Type',
    field: 'analytic'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'List of OR Words',
    field: 'listOrWords',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'List of AND Words',
    field: 'listAndWords'
  },
  {
    title: '5-Star',
    field: 'fiveStar'
  },
  {
    title: '4-Star',
    field: 'fourStar'
  },
  {
    title: '3-Star',
    field: 'threeStar'
  },
  {
    title: '2-Star',
    field: 'twoStar'
  },
  {
    title: '1-Star',
    field: 'oneStar'
  },
  {
    title: 'Star Rating',
    field: 'starRating'
  }
];



const summaryStarTableDataTableDataSet = summaryStarTableData.map((data) => {
  return ({
    id: data.id,
    model: data.Model,
    analytic: data['Analytic Type'],
    category: data['Category'],
    listOrWords: data['List of OR words'],
    listAndWords: data['List of AND words'],
    fiveStar: data['5-Star'],
    fourStar: data['4-Star'],
    threeStar: data['3-Star'],
    twoStar: data['2-Star'],
    oneStar: data['1-Star'],
    starRating: data['Average Star']
  })
});


// Category Detail - Trend Data

let categoryDetailTrendDataDate = categoryDetailTrendData.map(data => data.Date);
let categoryDetailTrendDataPositiveCumPercent = categoryDetailTrendData.map(data => data['Positive Cumulative Percent']);
let categoryDetailTrendDataNegativeCumPercent = categoryDetailTrendData.map(data => data['Negative Cumulative Percent']);
let categoryDetailTrendDataTotalCumPercent = categoryDetailTrendData.map(data => data['Cumulative Total']);

let categoryDetailTrendDataDataSet = {
  type: 'line',
  labels: categoryDetailTrendDataDate,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTrendDataPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTrendDataNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categoryDetailTrendDataTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categoryDetailTrendDataOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // // reset the data
      // setOpenModalCategorySummarySoftwareTrend(true); // Show Pop-up
      // setCategorySummarySoftwareTrendDrillDownData([]);
      // setCategorySummarySoftwareTrendPositiveDrillDownData([]);
      // setCategorySummarySoftwareTrendNegativeDrillDownData([]);
      // setModalTitle('');
      // setDrillDownIsLoading(true); 

      // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"software"}`;
      // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      // const axios = require('axios');
      // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
      //   // handle success

      //   setCategorySummarySoftwareTrendDrillDownData(response.data.individualSentenceDrillDown);
      //   setCategorySummarySoftwareTrendPositiveDrillDownData(response.data.topicPosList);
      //   setCategorySummarySoftwareTrendNegativeDrillDownData(response.data.topicNegList);
      //   setModalTitle(`${"Category = Software / "}${"Date = "}${xLabel}`);
        
      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
        
      //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      // });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Detail - Trend Table using react-data-table-component

const categoryDetailTrendDataTableDataSet = categoryDetailTrendData.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
const categoryDetailTrendDataTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Breakdown - Stack Bar Chart

let categoryDetailTopicBreakDownDataLabel = categoryDetailTopicBreakDownData.map(data => data['Topic List']);
let categoryDetailTopicBreakDownDataPositive = categoryDetailTopicBreakDownData.map(data => data['Positive']);
let categoryDetailTopicBreakDownDataNeutral = categoryDetailTopicBreakDownData.map(data => data['Neutral']);
let categoryDetailTopicBreakDownDataNegative = categoryDetailTopicBreakDownData.map(data => data['Negative']);

let categoryDetailTopicBreakDownDataSet = {
  type: 'bar',
  labels: categoryDetailTopicBreakDownDataLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicBreakDownDataPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailTopicBreakDownDataNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicBreakDownDataNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailTopicBreakDownDataOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // // reset the data
      // setOpenModalTopicInsightTopicPhrase(true); // Show Pop-up
      // setTopicInsightTopicPhraseDrillDownData([]);
      // setTopicInsightTopicPhrasedPositiveDrillDownData([]);
      // setTopicInsightTopicPhraseNegativeDrillDownData([]);
      // setModalTitle('');
      // setDrillDownIsLoading(true); 

      // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownTopicPhrase="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      // const axios = require('axios');
      // axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTopicPhraseDrillDown}${drillDownUrl}`).then(function (response) { 
      //   // handle success

      //   setTopicInsightTopicPhraseDrillDownData(response.data.individualSentenceDrillDown);
      //   setTopicInsightTopicPhrasedPositiveDrillDownData(response.data.topicDescriptionPos);
      //   setTopicInsightTopicPhraseNegativeDrillDownData(response.data.topicDescriptionNeg);
      //   setModalTitle(`${"Topic Phrase = "}${xLabel}`);
        
      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
        
      //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      // });

    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Detail - Topic Breakdown Table using react-data-table-component

const categoryDetailTopicBreakDownDataTableDataSet = categoryDetailTopicBreakDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic List'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
const categoryDetailTopicBreakDownDataTableColumn = [
  {
    name: 'Topic List',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Positive Wordcloud

const categoryDetailTopicDescriptionPositiveDataWords = categoryDetailTopicDescriptionPositiveData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const categoryDetailTopicDescriptionPositiveDataCallbacks = {
  onWordClick: (word) => {
    console.log(word.text);

    // // reset the data
    // setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
    // setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
    // setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
    // setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
    // setModalTitle('');
    // setDrillDownIsLoading(true); 

    // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Positive"}${"&drillDownTopic="}${word.text}`;
    // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
    // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
    // const axios = require('axios');
    // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
    //   // handle success

    //   setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
    //   setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
    //   setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
    //   setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
      
    // }).catch(function (error) { 
    //   // handle error
    //   console.log(error);
    // }).then(function () { 
    //   // always executed
      
    //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
    // });




  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryDetailTopicDescriptionPositiveDataOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryDetailTopicDescriptionPositiveDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryDetailTopicDescriptionPositiveDataCallbacks}
    options={categoryDetailTopicDescriptionPositiveDataOptions}
    words={categoryDetailTopicDescriptionPositiveDataWords} 
  />
}


// Category Detail - Positive Table using react-data-table-component

const categoryDetailTopicDescriptionPositiveDataTableDataSet = categoryDetailTopicDescriptionPositiveData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const categoryDetailTopicDescriptionPositiveDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Negative Wordcloud

const categoryDetailTopicDescriptionNegativeDataWords = categoryDetailTopicDescriptionNegativeData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const categoryDetailTopicDescriptionNegativeDataCallbacks = {
  onWordClick: (word) => {
    console.log(word.text);

    // // reset the data
    // setOpenModalTopicOverviewTopicComparisonSentimentNeg(true); // Show Pop-up
    // setCategoryOverviewTopicComparisonSentimentNegDrillDownData([]);
    // setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData([]);
    // setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData([]);
    // setModalTitle('');
    // setDrillDownIsLoading(true); 

    // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Negative"}${"&drillDownTopic="}${word.text}`;
    // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
    // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
    // const axios = require('axios');
    // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
    //   // handle success

    //   setCategoryOverviewTopicComparisonSentimentNegDrillDownData(response.data.individualSentenceDrillDown);
    //   setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData(response.data.topicDescriptionPos);
    //   setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData(response.data.topicDescriptionNeg);
    //   setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
      
    // }).catch(function (error) { 
    //   // handle error
    //   console.log(error);
    // }).then(function () { 
    //   // always executed
      
    //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
    // });




  }, 

  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryDetailTopicDescriptionNegativeDataOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryDetailTopicDescriptionNegativeDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryDetailTopicDescriptionNegativeDataCallbacks}
    options={categoryDetailTopicDescriptionNegativeDataOptions}
    words={categoryDetailTopicDescriptionNegativeDataWords} 
  />
}


// Category Detail - Negative Table using react-data-table-component

const categoryDetailTopicDescriptionNegativeDataTableDataSet = categoryDetailTopicDescriptionNegativeData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const categoryDetailTopicDescriptionNegativeDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail -  - View Individual Sentence - Table using material-table

const categoryDetailIndividualSentenceDataTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Star',
    field: 'star'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 400,
      minWidth: 400
    },
    headerStyle: {
      width: 400,
      minWidth: 400
    }
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  },
  {
    title: 'Positive list',
    field: 'posList'
  },
  {
    title: 'Negative list',
    field: 'negList'
  }
];



const categoryDetailIndividualSentenceDataTableDataSet = categoryDetailIndividualSentenceData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    model: data['Model'],
    website: data['Website'],
    country: data['Country'],
    reviewID: data['ReviewID'],
    category: data['Category'],
    topic: data['Topic'],
    star: data['Stars'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});



  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();

  return (
    <div>

    <div id="pageLoader" className={loadPage1}></div>

    <div id="mainPage" style={{display:loadPage2}}>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brand-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelection}
                  onChange={handleBrand}
                  inputProps={
                    {
                      name: "brandSelection",
                      id: "brand-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItems.map((BrandItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={BrandItems}>
                      {BrandItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItems}>
                        {categoryItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection}
                  onChange={handleModel}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems.map((modelItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItems}>
                      {modelItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="website-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select 
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={websiteSelection}
                  onChange={handleWebsite}
                  inputProps={
                    {
                      name: "websiteSelection",
                      id: "website-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  websiteItems.map((websiteItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={websiteItems}>
                      {websiteItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Topic</b>
              </h4>
            </CardHeader>
            <CardBody>
              <div className={classes.block} style={{height:"75px"}}>
                      <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uniqueTopic}
                            onChange={event => setUniqueTopic(event.target.checked)}
                            value="uniqueTopic"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTopic === true ? "ON" : "OFF (Recommended)"}
                      />
                    </div>
            </CardBody>
          </Card>

          </GridItem>


          <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon />
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Analytic</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="analytic-select"
                  className={
                    classes.selectLabel
                }>
                  Select Analytic
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={analyticSelection}
                  onChange={event => setAnalyticSelection(event.target.value)}
                  inputProps={
                    {
                      name: "analyticSelection",
                      id: "analytic-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  analyticItems.map((analyticItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={analyticItems}>
                        {analyticItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelection}
                    // onChange={handleDateStart}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 

                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelection}
                    // onChange={handleDateEnd}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">


            <CSVLink data={rawData} filename={"Raw Data.csv"}>
              <Button color="behance"> 
              <DownloadIcon className={classes.icons} />
                Download Raw
              </Button>
                </CSVLink>

            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

          </GridContainer>

          <br/>


{/*Summary Table */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Custom Category Overview</b> </Button>
</GridItem>

</GridContainer>


  <GridContainer>

  <GridItem xs={12} sm={12} md={12} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"
        
        tabs={[
          {
            tabName: "Summary Table by Sentiment",
            tabIcon: AssignmentIcon,
              tabContent: (!summaryTableDataIsLoading ? 
                <div>
                  
                  <div style={{ maxWidth: '100%' }}>
                          <MaterialTable
                            columns={summaryTableDataTableColumn}
                            data={summaryTableDataTableDataSet}
                            title="SMAT Review"
                            options={{
                              exportButton: true,
                              exportAllData: true,
                              filtering: true,
                              showTitle: false
                              //pageSize:"10"
                            }}
                          />
                    </div>

 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            {/* viewIndividualSentenceData
            viewIndividualSentenceDataTableDataSet */}
                <CSVLink data={summaryTableData} filename={"Summary Table by Sentiment.csv"}>
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          },

          {
            tabName: "Summary Table by Star",
            tabIcon: AssignmentIcon,
              tabContent: (!summaryTableDataIsLoading ? 
                <div>
                  
                  <div style={{ maxWidth: '100%' }}>
                          <MaterialTable
                            columns={summaryStarTableDataTableColumn}
                            data={summaryStarTableDataTableDataSet}
                            title="SMAT Review"
                            options={{
                              exportButton: true,
                              exportAllData: true,
                              filtering: true,
                              showTitle: false
                              //pageSize:"10"
                            }}
                          />
                    </div>

 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            {/* viewIndividualSentenceData
            viewIndividualSentenceDataTableDataSet */}
                <CSVLink data={summaryStarTableDataTableDataSet} filename={"Summary Table by Star.csv"}>
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

      </GridItem>

    </GridContainer>



{/*Category Detail */}

    <GridContainer>

    <GridItem xs={12}>
      <Button color="github" size="sm" style={{width: "100%"}}> <b>Category Detail</b> </Button>
    </GridItem>

    </GridContainer>


    <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={12}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TopicIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category List</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="custom-category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={customCategorySelection}
                  onChange={handleCustomCategorySelection}
                  inputProps={
                    {
                      name: "customCategorySelect",
                      id: "custom-category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  customCategoryItem.map((CustomCategoryItem, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={CustomCategoryItem}>
                      {CustomCategoryItem} </MenuItem>
                  ))
                } </Select>
              </FormControl>
              
            </CardBody>

          </Card>


          

        </GridItem>
        

        </GridContainer>


        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickCategoryDetailCharts}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

          </GridContainer>

          <br/>


{/* Value Box */}

          <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Positive Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailValueBoxPositive} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Neutral Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailValueBoxNeutral} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Negative Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailValueBoxNegative} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color={uniqueTrendStarColor} stats icon>
                <CardIcon color={uniqueTrendStarColor}>
                  <StarsIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Star Rating</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailValueBoxRating} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          </GridContainer>




          <br/>

            <GridContainer>

              <GridItem xs={2}>
                <Button color={customStarSelection.fiveStar ? "success" : "white"} size={customStarSelection.fiveStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleCustomStarSelection("fiveStar")}> <b>5-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={customStarSelection.fourStar ? "success" : "white"} size={customStarSelection.fourStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleCustomStarSelection("fourStar")}> <b>4-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={customStarSelection.threeStar ? "warning" : "white"} size={customStarSelection.threeStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleCustomStarSelection("threeStar")}> <b>3-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={customStarSelection.twoStar ? "danger" : "white"} size={customStarSelection.twoStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleCustomStarSelection("twoStar")}> <b>2-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={customStarSelection.oneStar ? "danger" : "white"} size={customStarSelection.oneStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleCustomStarSelection("oneStar")}> <b>1-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color = "info" size = "md" style={{width: "100%"}} onClick={() => handleCustomStarSelection("updateChart")}> <b>Refresh Chart</b> </Button>
              </GridItem>

            </GridContainer>

          <br/>

{/* Category Summary Trend Chart */}
    <GridContainer>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Category Trend",
                tabIcon: CategoryIcon,
                tabContent: (!categoryDetailDataIsLoading ? 
                  <div>
                    <Line 
                      height={215} data={categoryDetailTrendDataDataSet}  
                      options={categoryDetailTrendDataOptions} /> 
                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!categoryDetailDataIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={categoryDetailTrendDataTableColumn}
                        data={categoryDetailTrendDataTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={categoryDetailTrendData} filename={"Category Detail - Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>

      
      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Topic Sentiment Breakdown",
                tabIcon: TopicIcon,
                tabContent: (!categoryDetailDataIsLoading ? 
                  <div>
                    
                    <HorizontalBar 
                      height={215} data={categoryDetailTopicBreakDownDataSet} options={categoryDetailTopicBreakDownDataOption} /> 

                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!categoryDetailDataIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={categoryDetailTopicBreakDownDataTableColumn}
                        data={categoryDetailTopicBreakDownDataTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={categoryDetailTopicBreakDownData} filename={"Category Summary - Price Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                      }
                    ]}
                  /> 

              </GridItem>


          </GridContainer>



{/* Category Detail - Wordcloud  */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Sentiment List",
                    tabIcon: PositiveIcon,
                    tabContent: (!categoryDetailDataIsLoading ? 
                      <div style={{ height: 500}}>
                        {categoryDetailTopicDescriptionPositiveDataWordCloud()}
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!categoryDetailDataIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={categoryDetailTopicDescriptionPositiveDataTableColumn}
                            data={categoryDetailTopicDescriptionPositiveDataTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={categoryDetailTopicDescriptionPositiveData} filename={"Sentiment Words - Positive.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Topic List",
                    tabIcon: NegativeIcon,
                    tabContent: (!categoryDetailDataIsLoading ? 
                      <div style={{ height: 500}}>
                        {categoryDetailTopicDescriptionNegativeDataWordCloud()}
                      </div>

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!categoryDetailDataIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={categoryDetailTopicDescriptionNegativeDataTableColumn}
                            data={categoryDetailTopicDescriptionNegativeDataTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={categoryDetailTopicDescriptionNegativeData} filename={"Sentiment Words - Negative.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>




          </GridContainer>





{/*View Individual Sentence */}

              <GridContainer>

              <GridItem xs={12} sm={12} md={12} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="primary"
                    
                    tabs={[
                      {
                        tabName: "View Individual Sentence",
                        tabIcon: AssignmentIcon,
                          tabContent: (!categoryDetailDataIsLoading ? 
                            <div>
                              
                              <div style={{ maxWidth: '100%' }}>
                                      <MaterialTable
                                        columns={categoryDetailIndividualSentenceDataTableColumn}
                                        data={categoryDetailIndividualSentenceDataTableDataSet}
                                        title="SMAT Review"
                                        options={{
                                          exportButton: true,
                                          exportAllData: true,
                                          filtering: true,
                                          showTitle: false
                                        }}
                                        actions={[
                                          {
                                            icon: TopicIcon,
                                            tooltip: 'Show Full Review',
                                            onClick: (event, rowData) => {
                                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                              onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                              setFullReviewModal(true); // Show Pop-up

                                            }

                                          }
                                        ]}
                                        components={{
                                          Action: props => (

                                            <Tooltip
                                              id="tooltip-bottom"
                                              title="Show Full Review"
                                              placement="bottom"
                                              classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                  <FullReviewIcon />
                                                </Button>
                                            </Tooltip>
                                            
                                          )
                                        }}
                                      />
                                </div>

                                <div>
                                  <Dialog
                                    fullWidth={true}
                                    maxWidth={'lg'}
                                    open={fullReviewModal}
                                    onClose={() => setFullReviewModal(false)}
                                    //TransitionComponent={Transition}
                                    aria-labelledby="full-review-dialog-title1"
                                  >
                                    <DialogTitle id="full-review-dialog-title1"><strong> Full Review </strong></DialogTitle>
                                    <DialogContent>
                                      {/* <DialogContentText> */}
                                      {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                      {/* </DialogContentText> */}
                                    
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => setFullReviewModal(false)} color="primary">
                                        Close
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                            <CSVLink data={categoryDetailIndividualSentenceData} filename={"View Individual Sentence.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

            </GridContainer>


    </div></div>
  );
}
